import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
// import required modules
import { EffectCards } from 'swiper/modules';

const menuComida = [
    {
        id: 1,
        img: 'https://firebasestorage.googleapis.com/v0/b/imagenes-b8fc1.appspot.com/o/menu%2FPortada.png?alt=media&token=6c85e765-5cc7-40a3-978c-c6cf823e64c7',
        color: '#00b6c6'
    },
    {
        id: 2,
        img: 'https://firebasestorage.googleapis.com/v0/b/imagenes-b8fc1.appspot.com/o/menu%2F2.jpg?alt=media&token=33ebe99f-537b-426e-86cf-ef60c5d7ae0c',
        color: '#006f79'
    },
    {
        id: 3,
        img: 'https://firebasestorage.googleapis.com/v0/b/imagenes-b8fc1.appspot.com/o/menu%2F3.jpg?alt=media&token=4a07afc0-3109-4718-a6ba-f7343e4197ef',
        color: '#004a52'
    },
    {
        id: 4,
        img: 'https://firebasestorage.googleapis.com/v0/b/imagenes-b8fc1.appspot.com/o/menu%2F4.jpg?alt=media&token=9c8a5366-61b4-46b5-9062-e186edd1c610',
        color: '#8d5e32'
    },
    {
        id: 5,
        img: 'https://firebasestorage.googleapis.com/v0/b/imagenes-b8fc1.appspot.com/o/menu%2F5.jpg?alt=media&token=f2a269ea-0741-411c-b869-31dbefa9136a',
        color: '#1f2020'
    },
    {
        id: 6,
        img: 'https://firebasestorage.googleapis.com/v0/b/imagenes-b8fc1.appspot.com/o/menu%2F6.jpg?alt=media&token=06721341-f504-40b3-8992-b77627ff6718',
        color: '#5a3b1f'
    },
    {
        id: 7,
        img: 'https://firebasestorage.googleapis.com/v0/b/imagenes-b8fc1.appspot.com/o/menu%2F7.jpg?alt=media&token=2dd523de-98b5-4f54-ac09-5bc37c50738f',
        color: '#5a3b1f'
    },
    {
        id: 8,
        img: 'https://firebasestorage.googleapis.com/v0/b/imagenes-b8fc1.appspot.com/o/menu%2F8.jpg?alt=media&token=07705ede-cab5-49c1-839a-b9b23e73e7dc',
        color: '#004a52'
    },
]

const BlockMenuSwipper = () => {
    return (
        <div className='h-full'>
            <Swiper
                effect={'cards'}
                grabCursor={true}
                modules={[EffectCards]}
                className="mySwiper"
            >
                {
                    menuComida.map((item, index) => (
                        <SwiperSlide
                            key={index}
                        >
                            <img
                                className='w-full h-full md:h-[160vh] object-contain'
                                src={item.img}
                                alt={item.title}
                            />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    )
}

export default BlockMenuSwipper
