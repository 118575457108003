import React, { useState } from "react";
import TransparentHeader from "../components/global/TransparentHeader";
// import ContentServices from "../components/Services/ContentServices";
import BaseLayout from "../components/global/BaseLayout";
import BlockMenuSwipper from "../components/block/BlockMenuSwipper";
import { FaTimes } from "react-icons/fa";
// import ContentMenu from "../components/Services/ContentMenu";

function Services() {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <BaseLayout PageName="Menu">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Menu"
          Subheader="Menu"
        />
        {/* <div className="mainMenu">
          <div className="py-40 w-4/5 mx-auto relative">
            <ContentMenu />
          </div>
        </div> */}

        <div className="h-auto pb-36 md:pb-56">
          <div className="pb-20 pt-20 w-4/5 mx-auto flex flex-col justify-center items-center gap-5">
            <h1 className="font-bold text-[40px] text-center">Explore our diverse menu of delicious dishes.</h1>
            <p className="text-center">
              The menu features a wide variety of dishes, including appetizers, seafood, meats, Mexican favorites, breakfast options and beverages. Some of the featured dishes include several types of enchiladas, fajitas, seafood dishes such as shrimp al gusto and mojarra al gusto, and a selection of vegetarian options. The menu also includes a children's section, desserts and an extensive beverage list that includes cocktails, margaritas, beers and soft drinks.
            </p>
            <button className="bg-black text-white px-4 py-2 rounded-md" onClick={openModal}>Scan QR Code</button>
          </div>
          <div className="w-[80%] mx-auto">

            <BlockMenuSwipper />
          </div>
        </div>


      </div>

        {
          isModalOpen && (
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
              <div className="bg-white rounded-md p-4">
                <div className="flex justify-end">
                  <button onClick={closeModal} ><FaTimes className="text-xl font-bold cursor-pointer"/></button>
                </div>
                <h4 className="font-bold text-center">Scan QR Code</h4>
                <img src={`https://firebasestorage.googleapis.com/v0/b/imagenes-b8fc1.appspot.com/o/menu%2Fbit.ly_48voBnl%20(1).png?alt=media&token=78dc58fe-0832-4cd7-b9a2-fa89a072b076`} alt="QR Code" style={{ maxWidth: "100%" }} />

              </div>
            </div>
          )
        }

    </BaseLayout>
  );
}

export default Services;
